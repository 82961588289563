/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import { matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import { List } from "@material-ui/core";

import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { UserContext } from "src/context/User";
import Axios from "axios";
import ApiConfig from "src/config/APIConfig";
import { toast } from "react-toastify";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const NavBar = () => {
  useEffect(() => {
    user.getViewMyProfile();
    user.exchangeWallet();
  }, []);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const user = useContext(UserContext);
  const handleClose = () => {
    setOpen(false);
  };

  const BotStop = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.stopBot,
        headers: { token: token },
        params: { type: "LOGOUT" },
      });
      if (res?.data?.responseCode === 200) {
        toast.success(res?.data.responseMessage);
      }
    } catch (error) {
      console.log(error.response);
      if (error?.response?.data?.responseMessage) {
        toast.error(error?.response?.data?.responseMessage);
      } else {
        toast.error("Something went wrong please try again!");
      }
    }
  };

  const confirmationHandler = () => {
    BotStop();
    history.push("/");
    window.localStorage.removeItem("token");
    window.localStorage.clear();
  };
  const profilePic = user?.profile?.profilePic
    ? user?.profile?.profilePic
    : null;
  return (
    <>
      <img
        style={{ width: "35px", height: "35px", cursor: "pointer" }}
        src={profilePic ? profilePic : "/images/logOut.svg"}
        onClick={() => {
          setOpen(true);
        }}
        alt="img"
      />
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={"Confirmation"}
        desc={"Are you sure want to logout?"}
        confirmationHandler={confirmationHandler}
      />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
