import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  content: {
    maxHeight: "720px",
    // width: "100%",
    overflowY: "auto",
    maxWidth: "567px",
    margin: "0 auto",
    borderRadius: "10px",
    padding: "50px 30px",
    background: "transparent",
    border: "1px solid #0085FF",
    "@media(max-width:650px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      margin: "40px 0 40px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      background:
        "linear-gradient(180deg, rgba(47, 245, 255) 0%, rgba(2, 175, 184) 100%);",
    },
  },
  content1: {
    maxHeight: "720px",
    // width: "100%",
    overflowY: "auto",
    maxWidth: "567px",
    margin: "0 auto",
    borderRadius: "10px",
    padding: "50px",
    background: "transparent",
    border: "1px solid #0085FF",
    "@media(max-width:650px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      margin: "40px 0 40px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      background:
        "linear-gradient(180deg, rgba(47, 245, 255) 0%, rgba(2, 175, 184) 100%);",
    },
  },
  layoutPadding: {
    padding: "30px",
    // "@media(max-width:600px)": {
    //   padding: "40px 20px",
    //   padding: '130px 20px 30px 20px',
    // },
  },
  layoutPaddingR: {
    padding: "30px",
    // "@media(max-width:600px)": {
    //   padding: "40px 20px",
    //   padding: '50px 20px 30px 20px',
    // },
  },
  left: {
    height: "100%",
    "@media(max-width:959px)": {
      display: "none",
    },
  },

  mainbox: {
    height: "100%",
    padding: "0rem",
    paddingBottom: "0px",
    marginTop: "25px",
    paddingRight: "30px",
    paddingLeft: "30px",
  },
  logoImage: {
    position: "absolute",
    zIndex: "1",
    top: "20px",
    maxWidth: "225px",
    cursor: "pointer",
    "@media(max-width:1279px)": {
      display: "none",
    },
  },

  mainScreenBack: {
    maxWidth: "100%",
    minHeight: "100vh",
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/loginBackground.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "grid",
    alignItems: "center",
    "@media(max-width:959px)": {
      backgroundImage: "none",
    },
  },
  dontAccount: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#343434",
    textAlign: "center",
    marginTop: "21px",
  },
  logo: {
    cursor: "pointer",
    width: "149px",
    marginTop: "30px",
    paddingLeft: "60px",
  },
  inputvalue: {
    "&::placeholder": {
      color: "#fff !important",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "24px",
    },
    "& .MuiInputBase-input": {
      color: "#D9D9D9 !important",
      height: "24px",
      padding: "0 78px 0px 0px",
    },
    "@media(max-width:1279px)": {
      //width: "216%"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  // const themeSeeting = React.useContext(SettingsContext);
  // const inviteCode = "";
  // const signupUrl = "/register";
  return (
    <Box className={classes.mainScreenBack}>
      <Box
        className={
          location.pathname == "/register"
            ? classes.layoutPaddingR
            : classes.layoutPadding
        }
      >
        <Grid container>
          <Grid item xs={12} md={9}>
            <Box
              className={
                location.pathname == "/forget-password"
                  ? classes.content1
                  : classes.content
              }
            >
              {children}
              {location.pathname == "/" || location.pathname == "/forget-password" || location.pathname == "/verify-otp" ? (
                // <Typography
                //   onClick={() => history.push(signupUrl)}
                //   className={classes.dontAccount}
                // >
                //   Don’t have an account?{" "}
                //   <span style={{ color: "#0085FF", cursor: "pointer" }}>
                //     Register
                //   </span>
                // </Typography>
                <>
                </>
              ):""}{" "}
              {location.pathname == "/register" && (
                <Typography
                  onClick={() => history.push("/")}
                  className={classes.dontAccount}
                >
                  Already have an account?{" "}
                  <span style={{ color: "#0085FF", cursor: "pointer" }}>
                    Login
                  </span>
                </Typography>
              )}{" "}
            {location.pathname == "/reset-password" && (
              <Typography
                  onClick={() => history.push("/")}
                  className={classes.dontAccount}
                >
                  Back to?{" "}
                  <span style={{ color: "#0085FF", cursor: "pointer" }}>
                    Login
                  </span>
                </Typography>
            )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
