import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar/index";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#000",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    minHeight: "100vh",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    color: theme.palette.text.primary,
    backgroundPosition: "center",
    backgroundSize: "cover",
    maxWidth: "100%",
    minHeight: "100vh",
  },
  content: {
    paddingTop: "80px",
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "60px 30px 10px ",
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 18px 10px 18px !important",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <div
        style={
          location.pathname == "/account-setting" ||
          location.pathname == "/team"
            ? { display: "none" }
            : {
                display: "block",
                background:
                  "linear-gradient(180deg, #0085FF 12%, #005EB6 80.29%)",
              }
        }
      >
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
