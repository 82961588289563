import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/trade",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Trade/index")),
  },
  {
    exact: true,
    path: "/transactions",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Transactions/Transactions")),
  },
  {
    exact: true,
    path: "/bot-setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BotSetting/settings")),
  },
  // {
  //   exact: true,
  //   // path: "/register/:inviteCode?",
  //   path:"/register",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/signup/signup.js")),
  // },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  // {
  //   exact: true,
  //   path: "/terms&condition",
  //   guard: false,
  //   // layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/license/termsCondition")),
  // },
  // {
  //   exact: true,
  //   path: "/privacy-policy",
  //   guard: false,
  //   // layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/license/privacyPolicy")),
  // },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
