import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Dialog,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";
import DialogContent from "@material-ui/core/DialogContent";
// import NotificationList from "src/views/pages/notifications/index";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#2A2A2B",
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    borderRadius: "25px",
    // background:
    //   "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%)",
    height: 266,
    background: "#004AAD",
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },
  search: {
    height: "50px",
    width: "100%",
    maxWidth: "376px",
    color: "#ABABAB",
    borderRadius: "100px",
    display: "flex",
    backgroundColor: "#302F35",
    alignItems: "center",
    justifyContent: "left",
    margin: "0px 5px",
    marginTop: "5px",
    marginLeft: "8px",
    "& input": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchInput: {
    color: "#ABABAB",
    cursor: "text",
    display: "inline-flex",
    position: "relative",
    fontSize: "14px",
    boxSizing: "border-box",
    alignItems: "center",
    fontWeight: 400,
    lineHeight: "1.1976em",
    width: "71%",
  },
  searchIcon: {
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#fff",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  NotificationListStyle: {
    background: theme.palette.background.Notification,
    color: theme.palette.text.NotificationColor,
  },
  navbarIcon: {
    display: "flex",
  },
  linkcolor: {
    color: "#fff",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16.6667px",
    lineHeight: "29px",
  },
  iconColor: {
    "@media(max-width: 767px)": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
  BottomLine: {
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
  },
  TextBox: {
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "6.09524px",
    width: "100%",
    maxWidth: "430px",
    border: "1px solid #E1E3EA",
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "0px !important",
    },
    "& .MuiInputBase-input": {
      padding: "11px 15.64px",
    },
    "& .MuiInputBase-root": {
      color: "#000000",
      fontFamily: "Roboto",
      fontStyle: "normal",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  LogoDiv: {
    background: "#0085FF",
    height: "100%",
    width: "251px",
    "@media(max-width:1279px)": {
      display: "none",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();

  const location = useLocation();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  //   const getViewMyProfile = async (values) => {
  //     const token = localStorage.getItem("token");
  // console.log("sachin")
  //     try {
  //       const res = await Axios({
  //         method: "GET",
  //         url: ApiConfig.viewMyProfile,
  //         headers: { token: token },
  //       });
  //       if (res.data.statusCode === 200) {
  //         // setProfile(res.data.result);
  //       } else {
  //         // toast.error(res.data.responseMessage);
  //       }
  //     } catch (error) {
  //       // console.log(error);

  //       if (
  //         error.response.data.responseMessage === "jwt malformed"
  //       ) {
  //         history.push("/login");
  //         window.localStorage.clear();

  //         toast.error("jwt malformed")
  //       }
  //      else if (
  //         error.response.data.responseMessage ===
  //         "jwt expired"
  //       ) {
  //         history.push("/login");
  //         window.localStorage.clear();
  //         toast.error("Session  expired")
  //       }
  //     }
  //   };
  //   const token = localStorage.getItem("token");
  //   useEffect(() => {

  //     getViewMyProfile();
  //   }, [token]);
  // const readNotificationList = async () => {
  //   try {
  //     const res = await Axios({
  //       method: "GET",
  //       url: ApiConfig.readNotification,
  //       headers: {
  //         token: window.localStorage.getItem("token"),
  //       },
  //     });

  //     if (res.data.responseCode === 200) {
  //       setUnReadNotification(res?.data?.result?.unReadCount);
  //       user.NotificationDataList();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   readNotificationList();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <div className={classes.LogoDiv}>
        <Logo
          style={{
            cursor: "pointer",
            width: "167px",
            margin: "22px 35px",
          }}
        />
      </div>
      <Box style={{ paddingLeft: "20px", width: "50%" }}>
        <TextField
          id="inputID"
          variant="outlined"
          className={classes.TextBox}
          inputProps={{ maxLength: 256 }}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            className: classes.BottomLine,
            startAdornment: (
              <InputAdornment
                position="end"
                style={{
                  fontSize: "20px",
                  color: "#fff",
                }}
              >
                <img
                  alt="img"
                  src="images/magnifier.svg"
                  style={{ width: "25px", height: "25px" }}
                />
              </InputAdornment>
            ),
          }}
          placeholder="Search for transaction ID, Hash"
        />
      </Box>
      <Box flexGrow={1} />

      <SwipeableTemporaryDrawer />
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {/* <NotificationList
            isLoading={isLoading}
            count={user?.unReadNotification}
            notificationList={user?.notificationList}
            popUp={open}
          /> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
