const url = "https://nodepune-sandwichbot.mobiloitte.io/api/v1";
// const url = "http://172.16.2.16:3041/api/v1";

const ApiConfig = {
  login: `${url}/admin/login`,
  signup: `${url}/user/signup`,
  forgotPassword: `${url}/user/forgotPassword`,
  otpVerify: `${url}/user/verifySignUpOTP`,
  reset: `${url}/user/resetPassword`,
  startBot:`${url}/bot/startBot`,
  stopBot:`${url}/bot/stopBot`,
  getDashboardOverview:`${url}/bot/getDashboardOverview`,
  getFilterTransactionList:`${url}/bot/getFilterTransactionList`,
  getBotSetting:`${url}/bot/getBotSetting`,
  updateBotSetting:`${url}/bot/updateBotSetting`,
  changePassword:`${url}/admin/changePassword`,
  getAccountTransaction:`${url}/bot/getAccountTransaction`
};
export default ApiConfig;